<template>
  <div class="match-history-container bg-primary mb-2 pb-0">
    <div
      class="container-fluid bg-primary text-white py-3"
      style="border-left: none; border-right: none; border-bottom: 1px solid var(--primary-border-color)"
    >
      <div class="row align-items-center">
        <div class="col-md-4">
          <strong>Game Time: {{ utils.secondsToMinutes(match.gameTime) }}</strong>
        </div>
        <div class="col-md-4 text-center">
          <strong
            class="fs-5"
            :class="{
              'text-primary': match?.winnerName == 'blue',
              'text-danger': match?.winnerName === 'red',
            }"
          >
            Winner: {{ match.winnerName?.toUpperCase() }}
          </strong>
        </div>
        <div class="col-md-4 text-md-end">
          <strong>{{ calculateAgoTime }}</strong>
        </div>
      </div>
    </div>

    <div class="table-container">
      <table class="table table-dark table-hover align-center mb-0">
        <thead class="bg-secondary">
          <tr>
            <th scope="col">Hero</th>
            <th scope="col" class="name">Player</th>
            <th scope="col">K/D/A</th>
            <th scope="col">CS</th>
            <th scope="col">Dmg</th>
            <th scope="col">TD</th>
            <th scope="col">Heal</th>
            <th scope="col">Net</th>
            <th scope="col">FP</th>
            <th scope="col">Items</th>
            <th scope="col">Talents</th>
          </tr>
        </thead>

        <tbody ref="blueTeamTbody">
          <tr
            v-for="player in blueTeam"
            :key="player.id"
            class="clickable"
            style="background-color: rgba(100, 100, 255, 0.7)"
            @click="onClickName(`${player.name}`)"
          >
            <td><HeroIcon :hero_name="player.heroId" :size="iconSize"></HeroIcon></td>
            <td class="name">{{ player.name }}</td>
            <td>{{ player.kills }}/{{ player.deaths }}/{{ player.assists }}</td>
            <td>{{ player.lasthits }}</td>
            <td>{{ player.heroDamage }}</td>
            <td>{{ player.towerDamage }}</td>
            <td>{{ player.heroHealing }}</td>
            <td>{{ player.networth }}</td>
            <td>{{ player.fancyPoints }}</td>
            <td class="items-cell">
              <ItemIcon
                v-for="(itemName, index) in player.itemName"
                :key="index"
                :item_name="itemName"
                :size="iconSize"
              ></ItemIcon>
            </td>
            <td>{{ player.talents }}</td>
          </tr>

          <tr v-for="player in redTeam" :key="player.id" style="background-color: rgba(255, 100, 100, 0.7)">
            <td><HeroIcon :hero_name="player.heroId" :size="iconSize"></HeroIcon></td>
            <td class="name clickable" @click="onClickName(`${player.name}`)">{{ player.name }}</td>
            <td>{{ player.kills }}/{{ player.deaths }}/{{ player.assists }}</td>
            <td>{{ player.lasthits }}</td>
            <td>{{ player.heroDamage }}</td>
            <td>{{ player.towerDamage }}</td>
            <td>{{ player.heroHealing }}</td>
            <td>{{ player.networth }}</td>
            <td>{{ player.fancyPoints }}</td>
            <td class="items-cell">
              <ItemIcon
                v-for="(itemName, index) in player.itemName"
                :key="index"
                :item_name="itemName"
                :size="iconSize"
              ></ItemIcon>
            </td>
            <td>{{ player.talents }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import utils from '@/utils'
import HeroIcon from '@/components/hero_icon.vue'
import ItemIcon from '@/components/item_icon.vue'
import { format } from 'date-fns'

export default {
  name: 'KopMatchHistory',
  components: {
    HeroIcon,
    ItemIcon,
  },

  props: {
    match: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      blueTeam: [],
      redTeam: [],
      utils,
    }
  },

  computed: {
    formattedLocalTime() {
      if (this.match.playedAtUtc) {
        const date = new Date(this.match.playedAtUtc)
        return `${format(date, 'EEEE, MMMM do, yyyy')} at ${format(date, 'h:mm a')}`
      }
      return null
    },

    calculateAgoTime() {
      const date = new Date(this.match.playedAtUtc)
      const now = new Date()
      const diff = now - date
      const seconds = Math.floor(diff / 1000)
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)
      const weeks = Math.floor(days / 7)
      const months = Math.floor(weeks / 4)
      const years = Math.floor(months / 12)

      if (years > 0) {
        return `${years} year${years > 1 ? 's' : ''} ago`
      } else if (months > 0) {
        return `${months} month${months > 1 ? 's' : ''} ago`
      } else if (weeks > 0) {
        return `${weeks} week${weeks > 1 ? 's' : ''} ago`
      } else if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`
      } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
      } else {
        return `${seconds} second${seconds > 1 ? 's' : ''} ago`
      }
    },
  },

  async mounted() {
    // this.match = await utils.getMatch(this.gameId)

    const matchData = await Promise.all(
      this.match.MatchData.map(async (playerData) => {
        const additions = ['name', 'mmr']
        const player = await utils.getPlayerById(playerData.playerId, additions)
        return {
          name: player[0]?.name || 'Guest',
          ...playerData,
        }
      })
    )
    this.blueTeam = matchData.reduce((acc, playerData) => {
      if (playerData.team === 2) {
        acc.push(playerData)
      }
      return acc
    }, [])

    this.redTeam = matchData.reduce((acc, playerData) => {
      if (playerData.team === 1) {
        acc.push(playerData)
      }
      return acc
    }, [])
  },

  methods: {
    onClickName(name) {
      if (name === 'Guest') return
      this.$router.push({ name: 'Player', params: { name } })
    },
  },
}
</script>

<style scoped>
.match-history-container {
  width: 100%;
  overflow-x: auto;
}

.table-responsive {
  overflow-x: auto;
}

.align-center {
  text-align: center;
}

.name {
  min-width: 120px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  color: orange;
  cursor: pointer;
}

th {
  background-color: rgba(2, 24, 33, 0.7);
  border-bottom: 2px solid rgba(22, 44, 53, 1);
  white-space: nowrap;
  font-size: clamp(0.7rem, 1.5vw, 1rem);
}

tr {
  color: white !important;
  text-align: center;
}

td {
  background-color: rgba(2, 24, 33, 0.7);
  filter: brightness(0.8);
  border-bottom: 1px solid rgba(22, 44, 53, 1);
  font-size: clamp(0.7rem, 1.5vw, 1rem);
  white-space: nowrap;
}

.clickable {
  font-weight: bold;
  cursor: pointer;
}

.items-cell {
  white-space: nowrap;
}

@media (max-width: 768px) {
  .table-responsive {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .table-responsive {
    font-size: 0.7rem;
  }
}
</style>
