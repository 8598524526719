<template>
  <div>
    <div class="filter">
      <input ref="inputText" type="text" class="form-control" aria-label="Text input" placeholder="Filter Version" />
      <div class="isRanked">
        <label for="isRanked">Is Ranked: </label>
        <input id="isRanked" v-model="isRanked" type="checkbox" />
      </div>
      <button id="button-addon2" class="btn btn-primary br-2" type="button" @click.self="refetchValues">Search</button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col" class="bg-secondary"></th>
          <th scope="col" class="t-name bg-secondary" @click="sortBy('name')">Name</th>
          <th scope="col" class="t-games bg-secondary" @click="sortBy('games')">Games Played</th>
          <th scope="col" class="t-winrate bg-secondary" @click="sortBy('winrate')">Win Rate</th>
          <th scope="col" class="t-kda bg-secondary" @click="sortBy('kda')">KDA</th>
          <th
            scope="col"
            title="Games * Winrate * KDA"
            class="t-fancyPoints bg-secondary"
            @click="sortBy('fancyPoints')"
          >
            Avg. Fancy Points
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="{ games, winrate, kda, fancyPoints, name } in sortedItemDetails" :key="name">
          <td><ItemIcon :item_name="name" width="48" height="48"></ItemIcon></td>
          <td>{{ name }}</td>
          <td>{{ games }}</td>
          <td>{{ winrate }}</td>
          <td>{{ kda }}</td>
          <td>{{ fancyPoints }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import ItemIcon from '@/components/item_icon.vue'
import utils from '@/utils'

export default {
  name: 'KopItems',
  components: {
    ItemIcon,
  },

  data() {
    return {
      itemDetails: {},
      sortedItemDetails: {},
      utils,
      sortKey: 'games',
      sortDir: -1,
      isRanked: false,
    }
  },

  async mounted() {
    await this.fetchData()
    this.sortBy('games', 1)
  },

  methods: {
    async fetchData(version) {
      const matches = await this.utils.getMatches(
        5000,
        [
          this.utils.matchesAdditions.items,
          this.utils.matchesAdditions.kills,
          this.utils.matchesAdditions.deaths,
          this.utils.matchesAdditions.assists,
          this.utils.matchesAdditions.winner,
          this.utils.matchesAdditions.team,
          this.utils.matchesAdditions.fancyPoints,
        ],
        null,
        version,
        this.isRanked
      )

      this.itemDetails = {}

      for (let [key, value] of this.utils.itemNames) {
        if (key && value !== 'undefined' && value !== 'item_unk' && value != 'MoltenShield') {
          this.itemDetails[value] = {
            name: value,
            games: 0,
            wins: 0,
            losses: 0,
            winrate: 0,

            kills: 0,
            deaths: 0,
            assists: 0,
            kda: 0,

            fancyPoints: 0,
          }
        }
      }

      matches.forEach((match) => {
        this.utils.getAllPlayers(match).forEach((player) => {
          player.itemName.forEach((item) => {
            if (!this.itemDetails[item]) {
              return
            }
            this.itemDetails[item].games++
            if (player.won) {
              this.itemDetails[item].wins++
            } else {
              this.itemDetails[item].losses++
            }
            this.itemDetails[item].kills += player.kills
            this.itemDetails[item].deaths += player.deaths
            this.itemDetails[item].assists += player.assists
            this.itemDetails[item].fancyPoints += player.fancyPoints
          })
        })
      })

      for (let item in this.itemDetails) {
        this.itemDetails[item].winrate = ((this.itemDetails[item].wins / this.itemDetails[item].games) * 100).toFixed(2)
        this.itemDetails[item].kda = (
          (this.itemDetails[item].kills + this.itemDetails[item].assists) /
          this.itemDetails[item].deaths
        ).toFixed(2)
        this.itemDetails[item].fancyPoints = (
          this.itemDetails[item].fancyPoints / this.itemDetails[item].games
        ).toFixed(2)

        // If any of the values are NaN, set them to 0 ignore name
        for (let key in this.itemDetails[item]) {
          if (key === 'name') {
            continue
          }
          if (isNaN(this.itemDetails[item][key])) {
            this.itemDetails[item][key] = 0
          }
        }
      }
    },
    async refetchValues() {
      const version = this.$refs.inputText.value ?? null

      this.heroesDetails = {}
      this.sortedHeroDetails = {}
      await this.fetchData(version)
      this.sortBy('games', 1)
    },
    sortBy(key, dir) {
      const previousKey = this.sortKey
      // const previousDir = this.sortDir

      if (dir) {
        this.sortDir = dir
      } else {
        if (this.sortKey === key) {
          // Toggle direction if the same key is clicked
          this.sortDir = -this.sortDir
        } else {
          // Set to ascending for a new key
          this.sortDir = 1
          this.sortKey = key
        }
      }

      // Remove classes from the previous header
      if (previousKey) {
        const prevElement = document.querySelector(`.t-${previousKey}`)
        prevElement?.classList.remove('active', 'asc', 'desc')
      }

      // Add classes to the new header
      const currentElement = document.querySelector(`.t-${key}`)
      if (currentElement) {
        currentElement.classList.add('active')
        currentElement.classList.add(this.sortDir === 1 ? 'asc' : 'desc')
        currentElement.classList.remove(this.sortDir === 1 ? 'desc' : 'asc')
      }

      this.sortedItemDetails = Object.fromEntries(
        Object.entries(this.itemDetails).sort((a, b) => {
          let aValue = a[1][key]
          let bValue = b[1][key]

          if (aValue == null && bValue == null) return 0
          if (aValue == null) return this.sortDir
          if (bValue == null) return -this.sortDir

          const aNum = Number(aValue)
          const bNum = Number(bValue)

          if (!isNaN(aNum) && !isNaN(bNum)) {
            return (aNum - bNum) * this.sortDir
          }

          if (typeof aValue !== typeof bValue) {
            return typeof aValue < typeof bValue ? -this.sortDir : this.sortDir
          }

          if (typeof aValue === 'string') {
            return aValue.localeCompare(bValue) * this.sortDir
          } else if (aValue instanceof Date) {
            return (aValue.getTime() - bValue.getTime()) * this.sortDir
          }

          return aValue < bValue ? -this.sortDir : aValue > bValue ? this.sortDir : 0
        })
      )
    },
  },
}
</script>

<style scoped>
th {
  background-color: rgba(2, 24, 33, 0.7);
  border-bottom: 2px solid rgba(22, 44, 53, 1);
  cursor: pointer;
  user-select: none;
}

tr {
  text-align: center;

  color: white !important;
}
tr:hover {
  background-color: rgba(2, 24, 33, 0.7);
  filter: brightness(1);
}

td {
  background-color: var(--primary-color);
  filter: brightness(0.8);
  border-bottom: 1px solid rgba(22, 44, 53, 1);
}

.gold-text:before,
.gold-text:after {
  content: ' 👑 ';
}
.silver-text:before,
.silver-text:after {
  content: ' 🥈 ';
}
.bronze-text:before,
.bronze-text:after {
  content: ' 🥉 ';
}
.active {
  background-color: rgba(2, 24, 33, 0.7);
  filter: brightness(1.25);
  color: orange;
}

th.asc::after {
  content: ' ▲';
}

th.desc::after {
  content: ' ▼';
}

.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
  width: 40%;
  float: right;

  > input {
    flex: 3;
  }
  > button {
    flex: 1;
  }
}
.isRanked {
  color: white;
  font-weight: bold;

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
}
</style>
