<template>
  <div>
    <div class="filter">
      <div class="isRanked">
        <label for="isRanked">Is Ranked: </label>
        <input id="isRanked" v-model="isRanked" type="checkbox" />
      </div>
      <button id="button-addon2" class="btn btn-primary br-2" type="button" @click.self="fetchValues">Search</button>
    </div>
    <match v-for="match in matches" :key="match.id" :match="match"></match>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import HeroIcon from '@/components/hero_icon.vue'
import utils from '@/utils'
import match from '@/components/match.vue'

export default {
  name: 'KopLeaderboard',
  components: {
    HeroIcon,
    match,
  },

  data() {
    return {
      matches: [],
      utils,
      isRanked: false,
    }
  },

  async mounted() {
    await this.fetchValues()
  },
  methods: {
    async fetchValues() {
      this.matches = await this.utils.getMatches(
        75,
        [
          this.utils.matchesAdditions.winner,
          this.utils.matchesAdditions.heroId,
          this.utils.matchesAdditions.gameTime,
          this.utils.matchesAdditions.team,
          this.utils.matchesAdditions.fancyPoints,
          this.utils.matchesAdditions.kills,
          this.utils.matchesAdditions.deaths,
          this.utils.matchesAdditions.assists,
          this.utils.matchesAdditions.networth,
          this.utils.matchesAdditions.lasthits,
          this.utils.matchesAdditions.towerDamage,
          this.utils.matchesAdditions.heroHealing,
          this.utils.matchesAdditions.heroDamage,
          this.utils.matchesAdditions.damageTaken,
          this.utils.matchesAdditions.playedAtUtc,
          this.utils.matchesAdditions.items,
          this.utils.matchesAdditions.talents,
        ],
        null,
        null,
        this.isRanked
      )
    },
  },
}
</script>

<style scoped>
th {
  background-color: rgba(2, 24, 33, 0.7);
  border-bottom: 2px solid rgba(22, 44, 53, 1);
}

tr {
  color: white !important;
  text-align: center;
}
tr:hover {
  background-color: rgba(2, 24, 33, 0.7);
  filter: brightness(1);
}
td {
  background-color: rgba(2, 24, 33, 0.7);
  filter: brightness(0.8);
  border-bottom: 1px solid rgba(22, 44, 53, 1);
}

.red-team-text {
  color: rgb(238, 75, 43);
}
.blue-team-text {
  color: rgb(0, 150, 255);
}

.clickable {
  font-weight: bold;
  cursor: pointer;
}
.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
  width: 20%;
  float: right;

  > input {
    flex: 3;
  }
  > button {
    flex: 1;
  }
}
.isRanked {
  color: white;
  font-weight: bold;

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
}
</style>
